import uz from "./uz.json";
import ru from "./ru.json";
import en from "./en.json";

export default {
  uz: {
    main: uz,
  },
  ru: {
    main: ru,
  },
  en: {
    main: en,
  },
};
