import styled from "styled-components";
import colors from "../../assets/style/colors";

const MainStyle = styled.div`
  padding: 20px 50px 50px 50px;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  .container {
    width: 100%;
    .mainTable {
      border-collapse: collapse;
      width: 390px;
      margin-bottom: 20px;
      .tableNumbers {
        background-color: #3378c8;
        th {
          color: #ffffff;
          font-size: 14px;
          font-weight: lighter;
          height: 20px;
        }
      }
      .tableYear {
        background-color: #02398a;
        th {
          color: #ffffff;
          font-size: 16px;
        }
        .headerMiniCard {
          height: 64px;
        }
      }
      .tableDate {
        background-color: #e1edff;
        th {
          color: #000000;
          font-size: 14px;
          /* border: 2px solid black; */
          height: 20px;
        }
      }
      .tableBenefist {
        background-color: #3378c8;
        th {
          color: #ffffff;
          font-size: 14px;
          font-weight: lighter;
          /* height: 20px; */
        }
      }
      tbody {
        td {
          text-align: center;
        }
      }
      th,
      td {
        border: 1px solid #000000;
      }
    }
  }
  .inputMainCard {
    display: flex;
    .startTitle {
      margin: 0;
      padding-bottom: 5px;
    }
  }
  .searchCard {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    .input {
      border: 2px solid ${colors.gray};
      font-size: 14px;
      padding: 10px;
      border-radius: 5px;
      color: #434343;
      width: 280px;
      margin-bottom: 20px;
      margin-right: 20px;
    }
  }

  .between {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    .paginationBtn {
      border: none;
      background-color: ${colors.mainColor};
      color: #ffffff;
      padding: 5px 10px;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .tableCard {
    display: flex;

    .overflow {
      width: 1100px;
      overflow-x: auto;
      height: 100%;
      .mainTable2 {
        border-collapse: collapse;
        min-width: 200px;

        .tableNumbers {
          background-color: #3378c8;
          th {
            color: #ffffff;
            font-size: 14px;
            font-weight: lighter;
            /* height: 20px; */
          }
        }
        .tableYear {
          background-color: #02398a;
          th {
            color: #ffffff;
            font-size: 16px;
          }
          .headerMiniCard {
            /* height: 76px; */
          }
        }
        .tableDate {
          background-color: #e1edff;
          th {
            color: #000000;
            font-size: 14px;
            /* border: 2px solid black; */
            /* height: 20px; */
          }
        }
        .tableBenefist {
          background-color: #3378c8;
          th {
            color: #ffffff;
            font-size: 14px;
            font-weight: lighter;
            /* height: 19.5px; */
          }
        }
        .tbody {
          .benefist {
            /* font-weight: 700; */
          }

          td {
            text-align: center;
          }
        }
        th,
        td {
          border: 1px solid #000000;
        }
      }
    }
  }
  .listCard {
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .tableNumbers2 {
    background-color: #3378c8;
    th {
      color: #ffffff;
      font-size: 14px;
      font-weight: lighter;
      /* height: 20px; */
      min-width: 70px !important ;
    }
  }
  .fio {
    font-size: 13px;
    padding: 0 !important;
    margin: 0 !important;
    width: 150px !important;
  }
  .desc {
    font-size: 13px;
    padding: 0 !important;
    margin: 0 !important;
  }
  .numberTd {
    width: 15px !important;
    height: 45px !important;
  }
  .invoys {
    width: 30px !important;
    height: 45px !important;
  }
  .arrow {
    height: 45px !important;
  }

  .borderLeft2 {
    height: 45px !important;
  }

  .table3 {
    background-color: green;
  }
`;
export default MainStyle;
