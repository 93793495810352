import { createSlice } from "@reduxjs/toolkit";

export const main = createSlice({
  name: "main",
  initialState: {
    language: "uz",
    isFirst: true,
  },
  reducers: {
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
    setIsFirst: (state, action) => {
      console.log("render", action.payload);
      state.isFirst = action.payload;
    },
  },
});
