import React, { useCallback, useRef, useState } from "react";
import { get, isEmpty } from "lodash";
import api from "../../api";
import "react-activity/dist/library.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { main } from "../../store/slices/main";
import LoginStyle from "./LoginStyle";
import { useTranslation } from "react-i18next";
import Button from "../../components/Button";
import ErrorModal from "../../components/Modal/ErrorModal";

const Login = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const errorRef = useRef();
  const geterrorRef = useCallback((ref) => {
    errorRef.current = ref;
  }, []);

  const { setIsFirst } = main.actions;

  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const getDetails = () => {
    setLoading(true);
    api
      .post("Login", {
        CompanyDB: "AE06_TEST011",
        UserName: `sb1\\aew_user001a`,
        Password: "NoT-@Eda2",
      })
      .then(() => {
        setLoading(false);
        dispatch(setIsFirst(false));
        navigate("/main");
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        errorRef.current?.open();
      });
  };

  return (
    <LoginStyle>
      <div className='container'>
        <div style={{ width: "100%" }}>
          <div className='card'>
            <div>
              <label htmlFor='login' className='label'>
                {t("Login")}
              </label>
            </div>
            <input
              type='text'
              id='login'
              placeholder={t("Login")}
              defaultValue={login}
              className='input'
              onChange={(e) => setLogin(e.target.value)}
            />
          </div>
          <div className='card'>
            <div>
              <label htmlFor='password' className='label'>
                {t("Password")}
              </label>
            </div>
            <input
              type='text'
              id='password'
              placeholder={t("Password")}
              defaultValue={password}
              className='input'
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <div style={{ textAlign: "center" }}>
            <Button
              onClick={() => getDetails()}
              children={t("Вход")}
              isLoading={loading}
            />
          </div>
        </div>
      </div>
      <ErrorModal
        getRef={geterrorRef}
        title={t(
          "Login yoki parol xato, iltimos tekshirib qaytadan urunib ko'ring"
        )}
      />
    </LoginStyle>
  );
};

export default Login;
