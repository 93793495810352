import styled from "styled-components";

const ButtonStyle = styled.div`
  .btn {
    padding: 10px 30px;
    border-radius: 5px;
    background-color: #192d75;
    color: #ffffff;
    font-size: 18px;
    border: none;
    width: 150px;
    height: 40px;
  }
  .btn:hover {
    background-color: #1f388f;
  }
  .btn2 {
    padding: 10px 30px;
    border-radius: 5px;
    background-color: #192d755b;
    color: #ffffff;
    font-size: 18px;
    border: none;
    width: 150px;
    height: 40px;
  }
`;
export default ButtonStyle;
