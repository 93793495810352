import React from "react";
import ButtonStyle from "./ButtonStyle";
import { AiOutlineReload } from "react-icons/ai";

const Button = ({ isLoading, children, onClick }) => {
  if (isLoading) {
    return (
      <ButtonStyle>
        <button className='btn2' disabled={true}>
          <AiOutlineReload />
        </button>
      </ButtonStyle>
    );
  }
  return (
    <ButtonStyle>
      <button onClick={onClick} className='btn'>
        {children}
      </button>
    </ButtonStyle>
  );
};

export default Button;
