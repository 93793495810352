import React, { useState } from "react";
import MainStyle from "./MainStyle";
import api from "../../api";
import { get } from "lodash";
import Button from "../../components/Button";
import numberWithSpaces from "../../helpers/numberWithSpaces";

const Main = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [mainLoading, setMainLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [date, setDate] = useState([]);
  const [yearsData, setyearsData] = useState([]);
  const [headerSumData, setHeaderSumData] = useState([]);
  const [data, setData] = useState({
    data: [],
    currentPage: 0, //20
    nextPage: 0, //30
    oldPage: 0,
  });

  const getOrders = (currentPage = 0, oldDataPage = 0) => {
    setIsLoading(true);
    setMainLoading(false);
    api
      .get(
        `SQLQueries('getInstallments')/List?endDate='${endDate}T00:00:00Z'&startDate='${startDate}T00:00:00Z'&$skip=${currentPage}`
      )
      .then((res) => {
        const resData = get(JSON.parse(res.data), "value", []);
        const nextPage = Number(
          get(JSON.parse(res.data), "@odata.nextLink", "skip=0").split(
            "skip="
          )[1]
        );

        getAllMonths(startDate, endDate);

        const a = resData.reduce((acc, elem) => {
          const b = acc[elem.DocEntry] === undefined ? [] : acc[elem.DocEntry];
          acc[elem.DocEntry] = [...b, elem];
          return acc;
        }, {});
        const currentData = Object.keys(a).map((DocEntry) => {
          return a[DocEntry];
        });
        const aaa = currentData.map((itemC, indexC) => {
          if (itemC.length < date.length) {
            return date.map((v, i) => {
              let f = 0;
              let miniObject = {};
              for (let j = 0; j < date.length; j++) {
                if (
                  get(itemC[j], "DueDate", "").slice(4, 6) === v.slice(5, 7)
                ) {
                  f++;
                  miniObject = itemC[j];
                }
              }
              if (f == 1) {
                return miniObject;
              } else {
                return { DueDate: "-" };
              }
            });
          } else {
            return itemC;
          }
        });

        sum(aaa);
        console.log(aaa);

        if (oldDataPage === 0) {
          setData({
            data: [...aaa],
            currentPage: currentPage === 0 ? 0 : data.nextPage,
            nextPage: nextPage === 0 ? data.currentPage : nextPage,
            oldPage: nextPage === 0 ? data.currentPage : nextPage - 40,
          });
        } else {
          setData({
            data: [...aaa],
            currentPage: data.nextPage - 40,
            nextPage: nextPage,
            oldPage: nextPage < data.currentPage ? nextPage : nextPage - 40,
          });
        }
        setIsLoading(false);
        setMainLoading(true);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        console.log(JSON.stringify(err, null, 2));
        console.log("err", get(err, "response", {}));
      });
  };

  const sum = (arr) => {
    let sumDate = [];

    for (let i = 0; i < date.length; i++) {
      let obj = {
        leftNumber: 0,
        centerNumber: 0,
        rightNumber: 0,
      };

      for (let u = 0; u < arr.length; u++) {
        obj = get(arr[u][i], "GrossBuyPr", "")
          ? {
              leftNumber: obj.leftNumber + get(arr[u][i], "GrossBuyPr", ""),
              centerNumber:
                obj.centerNumber +
                (get(arr[u][i], "LineTotal", "-") -
                  get(arr[u][i], "GrossBuyPr", "-")),
              rightNumber: obj.rightNumber + get(arr[u][i], "LineTotal", "-"),
            }
          : {
              leftNumber: obj.leftNumber,
              centerNumber: obj.centerNumber,
              rightNumber: obj.rightNumber,
            };
      }
      sumDate.push(obj);
    }

    setHeaderSumData(sumDate);
  };

  const getAllMonths = (startDate, endDate) => {
    const months = [];
    const start = new Date(startDate);
    const end = new Date(endDate);
    let current = new Date(start);

    while (current <= end) {
      const year = current.getFullYear();
      const month = current.getMonth() + 1; // Adding 1 since getMonth() returns zero-based index

      months.push(`${year}-${month.toString().padStart(2, "0")}`);
      current.setMonth(current.getMonth() + 1); // Move to the next month
    }
    const years = months.map((v, i) => {
      return v.slice(0, 4);
    });
    const occurrences = {};
    years.forEach((num) => {
      occurrences[num] = (occurrences[num] || 0) + 1;
    });
    setDate(months);
    setyearsData(occurrences);
  };

  const newDatas = () => {
    if (data.nextPage > data.currentPage) {
      getOrders(data.nextPage, 0);
    } else {
      alert("boshqa malumot yoq");
    }
  };

  const oldData = () => {
    if (data.oldPage < 0) {
      alert("boshqa malumot yoq");
    } else {
      getOrders(data.oldPage, 1);
    }
  };

  return (
    <>
      <MainStyle>
        <div className='container'>
          {/* <h1>Jadval</h1> */}
          <div className='searchCard'>
            <div className='inputMainCard'>
              <div>
                <p className='startTitle'>Boshlang'ich sanasi</p>
                <input
                  id='start'
                  type='date'
                  className='input'
                  placeholder='Start date'
                  defaultValue={startDate}
                  onChange={(v) => setStartDate(v.target.value)}
                />
              </div>
              <div>
                <p className='startTitle'>Tugash sanasi</p>
                <input
                  id='end'
                  type='date'
                  className='input'
                  placeholder='End date'
                  defaultValue={endDate}
                  onChange={(v) => setEndDate(v.target.value)}
                />
              </div>
            </div>
            <Button onClick={() => getOrders(0, 0)} isLoading={isLoading}>
              Поиск
            </Button>
          </div>

          {mainLoading ? (
            <>
              <div className='tableCard'>
                <table className='mainTable'>
                  <thead>
                    {/* 1-qator; yil */}
                    <tr className='tableYear'>
                      <th
                        colSpan={4}
                        rowSpan={1}
                        className='headerMiniCard'
                      ></th>
                    </tr>

                    {/* 4-qator; foyda */}
                    <tr className='tableBenefist'>
                      <th className='nTh'>N</th>
                      <th>Invoys</th>
                      <th>Klienat</th>
                      <th>Tovar turi</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.data.map((item, index) => {
                      const sameA = item.find((vv) => {
                        return get(vv, "Dscription", "");
                      });
                      return (
                        <tr key={index}>
                          <td className='numberTd'>{index + 1}</td>
                          <td className='invoys'>
                            {get(sameA, "DocEntry", "")}
                          </td>
                          <td className='fio'>{get(sameA, "CardName", "")}</td>
                          <td className='desc'>
                            {get(sameA, "Dscription", "")}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>

                <div className='overflow'>
                  <table className='mainTable2'>
                    <thead>
                      {/* 1-qator; yil */}
                      <tr className='tableYear'>
                        {yearsData[2023] ? (
                          <th colSpan={Number(yearsData[2023]) * 3}>2023</th>
                        ) : null}
                        {yearsData[2024] ? (
                          <th th colSpan={Number(yearsData[2024]) * 3}>
                            2024
                          </th>
                        ) : null}
                        {yearsData[2025] ? (
                          <th th colSpan={Number(yearsData[2025]) * 3}>
                            2025
                          </th>
                        ) : null}
                        {yearsData[2026] ? (
                          <th th colSpan={Number(yearsData[2026]) * 3}>
                            2026
                          </th>
                        ) : null}
                        {yearsData[2027] ? (
                          <th th colSpan={Number(yearsData[2027]) * 3}>
                            2027
                          </th>
                        ) : null}
                        {yearsData[2028] ? (
                          <th th colSpan={Number(yearsData[2028]) * 3}>
                            2028
                          </th>
                        ) : null}
                      </tr>

                      {/* 2-qator; sonlar */}
                      <tr className='tableNumbers2'>
                        {headerSumData.map((v, i) => {
                          return (
                            <>
                              <th>
                                {numberWithSpaces(get(v, "leftNumber", ""))}
                              </th>
                              <th>
                                {numberWithSpaces(get(v, "centerNumber", ""))}
                              </th>
                              <th>
                                {numberWithSpaces(get(v, "rightNumber", ""))}
                              </th>
                            </>
                          );
                        })}
                      </tr>

                      {/* 3-qator; sana */}
                      <tr className='tableDate'>
                        {date.map((v, i) => {
                          return <th colSpan={3}>{v}</th>;
                        })}
                      </tr>

                      {/* 4-qator; foyda */}
                      <tr className='tableBenefist'>
                        {date.map((v, i) => (
                          <>
                            <th className='borderLeft'>Ta'ni</th>
                            <th>Foyda</th>
                            <th className='borderRight'>Jami</th>
                          </>
                        ))}
                      </tr>
                    </thead>
                    <tbody className='tbody'>
                      {data.data.map((item, index) => {
                        return (
                          <tr key={index}>
                            {item.map((v, i) => {
                              let predOplata = get(v, "U_Prepayment", 0);
                              let PrSum =
                                v === "-" ||
                                get(v, "U_Prepayment", null) == null
                                  ? 0
                                  : get(v, "U_Prepayment", 0);

                              let birinchiOy = get(v, "InstlmntID", 0);
                              console.log("PrSum", PrSum);

                              return birinchiOy === 1 && predOplata != null ? (
                                <>
                                  <td>-</td>
                                  <td>-</td>
                                  <td>-</td>
                                </>
                              ) : (
                                <>
                                  <td className='borderLeft2' key={i}>
                                    {(
                                      get(v, "InsTotal", 0) -
                                      ((get(v, "LineTotal", 1) -
                                        get(v, "GrossBuyPr", 0)) /
                                        (get(v, "LineTotal", 1) - PrSum)) *
                                        get(v, "InsTotal", 0)
                                    ).toFixed(1)}
                                  </td>
                                  <td className='borderLeft2'>
                                    {numberWithSpaces(
                                      (
                                        ((get(v, "LineTotal", 1) -
                                          get(v, "GrossBuyPr", 0)) /
                                          (get(v, "LineTotal", 1) - PrSum)) *
                                        get(v, "InsTotal", 0)
                                      ).toFixed(1)
                                    )}
                                  </td>
                                  <td className='borderLeft2'>
                                    {numberWithSpaces(get(v, "InsTotal", 0))}
                                  </td>
                                </>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className='between'>
                <button className='paginationBtn' onClick={oldData}>
                  {"<"}
                </button>
                <button className='paginationBtn' onClick={newDatas}>
                  {">"}
                </button>
              </div>
            </>
          ) : (
            <div className='listCard'>
              <h1>
                Ro'yxat yaratish uchun boshlanish va tugash sanalarini tanlang !
              </h1>
            </div>
          )}
        </div>
      </MainStyle>
    </>
  );
};

export default Main;
