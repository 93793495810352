import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Launch, Login, Main } from "../screens";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Launch />} />
        <Route path='/login' element={<Login />} />
        <Route path='/main' element={<Main />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
